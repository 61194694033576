style {
  .demo-page-wrapper {
    width:15em;
    height:19em;
    position:relative;
    pointer-events: none;
    line-height: 1.6;
  }
  .demo-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    width:30em;
    height:38em;
    font-size:4em;
    transform-origin: top left;
    transform: scale(0.125);
    position: relative;
    border-radius: 0.3em;
    box-shadow: 0 1.5em 1.5em rgba(0,0,0,0.3);
    overflow: hidden;
  }
  .demo-page-progress {
    position:absolute;
    top:0;
    left:0;
    height:0.5em;
    background: linear-gradient(90deg, #25dbd2, #33d5f4, #039fea);
    transform-origin: 0 0;
  }
  .demo-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:1em;
  }
  .demo-page-logo {
    font-size:2em;
    font-weight: 800;
  }
  .demo-page-cart {
    background: #1d1924;
    color:#fff;
    padding:0.5em 1em;
  }
  .demo-page-product {
    display: flex;
    justify-content: space-between;
    padding:1em;
  }
  .demo-page-image {
    flex: 1;
  }
  .demo-page-description {
    flex: 1;
  }
  .demo-page-description-title {
    font-size: 1.2em;
    font-weight: 600;
    display: block;
  }
  .demo-page-description-price {
    font-size: 1.2em;
    font-weight: 600;
    display: block;
  }
  .demo-page-description-button {
    background: #1d1924;
    color:#fff;
    padding:0.5em 1em;
    display: inline-block;
    margin:0.25em 0;
  }
  .demo-page-description-text {
    font-size: 0.8em;
    display: block;
  }
  .demo-page-review {
    padding:1em;
  }
  .demo-page-rating {
    color:#fec703;
  }
  .demo-page-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding:1em;
    background:#f4f2f4;
  }
  .demo-page-footer > div {
    font-size: 0.8em;
  }
  .demo-page-loading {
    opacity: 0;
  }
  .demo-page-lowres {
    filter: blur(1em) grayscale(0.6);
    opacity: 0.3;
  }
  .demo-page-hydrate-partial .demo-page-hydrated,
  .demo-page-hydrate-all div {
    animation-name: pulse-red;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
  .demo-page-delay-1 {
    animation-delay: 100ms;
  }
  .demo-page-delay-2 {
    animation-delay: 200ms;
  }
  .demo-page-delay-3 {
    animation-delay: 300ms;
  }
  .demo-page-delay-4 {
    animation-delay: 400ms;
  }
  .demo-page-delay-5 {
    animation-delay: 500ms;
  }
  .demo-page-delay-6 {
    animation-delay: 600ms;
  }
  .demo-page-delay-7 {
    animation-delay: 700ms;
  }
  .demo-page-delay-8 {
    animation-delay: 800ms;
  }
  .demo-page-delay-9 {
    animation-delay: 900ms;
  }
  .demo-page-delay-10 {
    animation-delay: 1000ms;
  }
  .demo-page-delay-11 {
    animation-delay: 1100ms;
  }
  .demo-page-delay-12 {
    animation-delay: 1200ms;
  }
  .demo-page-delay-13 {
    animation-delay: 1300ms;
  }
  .demo-page-delay-14 {
    animation-delay: 1400ms;
  }
  .demo-page-delay-15 {
    animation-delay: 1500ms;
  }
  .demo-page-delay-16 {
    animation-delay: 1600ms;
  }
  .demo-page-delay-17 {
    animation-delay: 1700ms;
  }

  @media (prefers-reduced-motion) {
    .demo-page-hydrate-partial .demo-page-hydrated,
    .demo-page-hydrate-all div {
      animation: none;
      box-shadow: 0 0 0 15px rgba(255, 50, 82, 0.7);
    }
  }

  @keyframes pulse-red {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 50, 82, 0.9);
    }

    100% {
      box-shadow: 0 0 0px 90px rgba(255, 50, 82, 0);
    }
  }

  .demo-page-marko,
  .demo-page-other {
    position: absolute;
    bottom: -0.5em; right:-0.5em;
    height: 3em;
    width: 3em;
    border-radius: 50%;
    background:#fff;
    box-shadow: 0 0 0.75em rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .demo-page-marko {
    background: conic-gradient(#fedd01, #fa8b01, #f41a4e, #a81561, #981591, #0579c1, #3dd6f7, #1de1b7, #82e418, #fedd01);
  }
  .demo-page-icon-inner {
    background: rgba(255,255,255,0.9);
    height: 2.75em;
    width: 2.75em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .demo-page-marko img {
    width:2em;
  }
  .demo-page-other img {
    width:1em;
  }
  .demo-page-other {
    background: linear-gradient(135deg, rgba(255, 50, 82), #a41560);
  }
  .demo-page-other .demo-page-icon-inner {
    background: rgba(255,255,255,0.85);
  }
}

$ const progress = input.buffered ? Math.floor(input.progress / 0.9) : input.progress / 0.9;

<div.demo-page-wrapper class=input.class role="img" aria-label=input.label> 
  <div.demo-page-container aria-hidden="true" class={ "demo-page-hydrate-all":input.hydrateAll, "demo-page-hydrate-partial":input.hydratePartial }>
    <if(input.progress >= 0)>
      <div.demo-page-progress style={ transform: `scaleX(${Math.min(1, input.progress * (input.buffered ? 1 : 1/0.9))})` }/>
    </if>
    <div.demo-page-header.demo-page-delay-1 class={ "demo-page-loading": progress < 0.1 }>
      <div.demo-page-link.demo-page-delay-2>All Products</div>
      <div.demo-page-logo.demo-page-delay-3>BuyItNow</div>
      <div.demo-page-cart.demo-page-delay-4.demo-page-hydrated>Cart (0)</div>
    </div>
    <div.demo-page-product.demo-page-delay-5 class={ "demo-page-loading": progress < 0.4 }>
      <img.demo-page-image class={ "demo-page-lowres": progress < 0.6 || (input.buffered && input.progress < 1) } src="./product.png" alt=""/>
      <div.demo-page-description.demo-page-delay-6>
        <span.demo-page-description-title>Google Home - $79</span>
        <div.demo-page-description-button.demo-page-delay-7.demo-page-hydrated>Add to Cart</div>
        <span.demo-page-description-text>Hands-free help around the house. Google Home is a smart speaker with the Google Assistant built in. So whenever you need help, it's by your side</span>
      </div>
    </div>
    <div.demo-page-review.demo-page-delay-8 class={ "demo-page-loading": progress < 0.8 }>
      <div.demo-page-rating.demo-page-delay-9>
        ★★★★☆
      </div>
      <span.demo-page-description-text>
        <strong>Cool gadget</strong>
        Google has created a nice device that provides music and information by voice control. The microphone is very good and will usually pick up commands from across the room. The speakers sound surprisingly good for such a small device. I wish it had tone control though.
      </span>
    </div>
    <div.demo-page-review.demo-page-delay-10 class={ "demo-page-loading": progress < 1 }>
      <div.demo-page-rating.demo-page-delay-11>
        ★★★★★
      </div>
      <span.demo-page-description-text>
        <strong>Incredible sound profile!</strong>
        Easy setup, great sound for any room size. Adjustable bass and treble. Currently have two paired up for better whole house sound. 
      </span>
    </div>
    <div.demo-page-footer.demo-page-delay-12 class={ "demo-page-loading": progress < 0.1 }>
      <div.demo-page-delay-13>About</div>
      <div.demo-page-delay-14>Security</div>
      <div.demo-page-delay-15>Policies</div>
      <div.demo-page-delay-16>Help</div>
      <div.demo-page-delay-17>Sitemap</div>
    </div>
  </div>
  <if(input.buffered || input.hydrateAll)>
    <div.demo-page-other>
      <div.demo-page-icon-inner>
        <img src="./x.svg" alt=""/>
      </div>
    </div>
  </if>
  <else>
    <div.demo-page-marko>
      <div.demo-page-icon-inner>
        <img src="../../../../logos/marko.svg" alt=""/>
      </div>
    </div>
  </else>
</div>
