class {
  onCreate() {
    this.state = { count: 0 };
  }
  increment() {
    this.state.count++;
  }
}
<div>${state.count}</div>
<button on-click("increment")>
  Click me!
</button>
